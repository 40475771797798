<template>
  <div>
    <vx-card class="mt-10 learn-pv">
      <h2>Learning Centres Philosophies and Values</h2>
      <div class="fullwidth-banner">
        <img :src="bannerPhoto">
      </div>
      <p v-html="description"></p>
    </vx-card>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  data() {
    return {
      centerId:null,
      description:null,
      bannerPhoto:null,
    }
  },
  watch: {
  },
  methods: {
    ...mapActions("philosophies", [
      "getPhilosophiesByCenter",
    ]),

    async getPhilosophiesAndValueByCenter(centerId) {
      await this.getPhilosophiesByCenter(centerId).then((response) => {
        let data = response.data.data;
        this.description = data.description;
        this.bannerPhoto = data.bannerPhoto;
      }).catch(err => {
        this.$vs.loading.close();
      })
    },
  },
  async mounted() {
    this.centerId = this.$store.state.AppParentSelectedChild.learningCenterId;
    await this.getPhilosophiesAndValueByCenter(this.centerId);
  },
}
</script>
